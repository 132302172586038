import { useTranslation } from "react-i18next";

const Path = ({title, setSearch}) => {
    const {t} = useTranslation()

    return <div className="d-flex mb-5 align-items-center
    justify-content-between">
    <div className="paths">
        {!!title && <h1>{t(title)}</h1>}
    </div>
    {(title === 'products' || title === 'categories') && <div>
        <input 
            type="text" 
            name='search'
            placeholder={t("Search")} 
            onChange={e=> setSearch(e.target.value)}
            style={{
                padding: '6px 16px',
                border: '1px solid #dedede',
                borderRadius: '8px',
                width: '18rem'
            
            }}
        />
    </div>}
    </div>
}
export default Path;