export const ar = {
    "THE RENT": "الإيجار",
    "Get Started": "البدء",
    "home": "الصفحه الرئيسيه",
    "Start From": "ابدأ من",
    "total": "المجموع",
    "products": "المنتجات",
    "categories": "الأقسام",
    "about-us": "من نحن",
    "Quick Links": "روابط سريعة",
    "Contact Details": "تفاصيل الاتصال",
    "Follow Us": "تابعنا",
    "Legal": "القانوني",
    "Start Day": "يوم البدء",
    "The page you were looking for is not found!": "الصفحة التي تبحث عنها لم يتم العثور عليها!",
    "You may have mistyped the address or the page may have moved.": "ربما أخطأت في كتابة العنوان أو ربما تم نقل الصفحة.",
    "KWD": "د.ك",
    "Please agree to the following terms before proceeding": "يرجى الموافقة على الشروط التالية قبل المتابعة",
    "Shop By Category": "تسوق حسب القسم",
    "View All": "عرض الكل",
    "Explore All": "استكشاف الكل",
    "Explore": "استكشف",
    "New Arrivals": "وصل حديثا",
    "Best Selling": "الأكثر مبيعا",
    "Exclusive Products": "المنتجات الحصرية",
    "Feature Products": "المنتجات المميزة",
    "QUALITY": "الجودة التي يقدمها لك MASTER HN",
    "Best Selling Products": "أفضل المنتجات مبيعا",
    "DOWNLOAD MASTER HN": "تحميل Master HN",
    "Style Up Your Looks": "صمم مطبخك",
    "All Rights": "كل الحقوق محفوظة",
    "Hasan Al Naser": "حسن الناصر",
    "New Collection": "المجموعة الجديدة",
    "Powered By": "مدعوم من",
    "Send Message": "إرسال رسالة",
    "Total Price": "السعر الإجمالي",
    "Continue": "متابعة",
    "Your Cart is Empty": "عربة التسوق فارغة",
    "Cart": "عربة التسوق",
    "Period": "الفترة",
    "Days": "الأيام",
    "Please fill out the form below to schedule a consultation or ask any questions. Our team will get back to you promptly.": "يرجى ملء النموذج أدناه لتحديد موعد استشارة أو طرح أي أسئلة. سيتواصل فريقنا معك على الفور.",
    "We're Here to Help with All Your Desert  Needs": "نحن هنا لمساعدتك في جميع احتياجاتك الصحراوية",
    "KWD / Per Day": "د.ك / لليوم",
    "KWD / Per Days": "د.ك / للأيام",
    "Download Pdf": "تحميل الملف",
    "Style Up": "صمم",
    "Add To Cart": "أضف إلى السلة",
    "contact": "اتصل بنا",
    "Name": "الإسم",
    "Email": "البريد الإلكتروني",
    "Phone": "رقم الموبايل",
    "Message": "الرسالة",
    "CONTACT US ON WHATSAPP": "تواصل معنا عبر واتساب",
    "GET IN TOUCH": "ابق على تواصل",
    "Product": "المنتج",
    "Price": "السعر",
    "Quantity": "الكمية",
    "Total": "الإجمالي",
    "Sub Total": "المجموع الفرعي",
    "Payment": "الدفع",
    "Cancel Order": "إلغاء الطلب",
    "Cancel Message": "يرجى ملاحظة أن هذا الإجراء لا يمكن التراجع عنه وسيتم إلغاء الطلب",
    "Close": "إغلاق",
    "Done": "تم",
    "Payment Details": "تفاصيل الدفع",
    "Proceed": "هل ترغب في المتابعة لإتمام الطلب كـ",
    "Login": "تسجيل الدخول",
    "Continue as a Guest": "المتابعة كزائر",
    "Login Into Your Account": "تسجيل الدخول إلى حسابك",
    "Create New Account": "إنشاء حساب جديد",
    "Password": "الرقم السري",
    "Don't Have Account": "ليس لديك حساب",
    "Sign Up": "التسجيل",
    "Have Account": "لديك حساب",
    "Checkout": "إتمام الشراء",
    "No Orders": "لا يوجد طلبات",
    "User Details": "تفاصيل المستخدم",
    "Full Name": "الاسم الكامل",
    "Address Details": "تفاصيل العنوان",
    "Details": "تفاصيل",
    "Address": "العنوان",
    "Area": "المنطقة",
    "Block": "البلوك",
    "view": "عرض",
    "Street": "الشارع",
    "Avenue": "الجادة",
    "Floor Number": "رقم الطابق",
    "status": "الحالة",
    "Order Date": "تاريخ الطلب",
    "Order ID": "الطلب ID",
    "Optional": "اختياري",
    "Address Type": "نوع العنوان",
    "Building Number": "رقم المبنى",
    "Floor": "الطابق",
    "Apartment Number": "رقم الشقة",
    "House Number": "رقم المنزل",
    "Office Number": "رقم المكتب",
    "Extra Directions": "اتجاهات إضافية",
    "Other Instructions": "تعليمات أخرى",
    "Please Enter": "الرجاء إدخال",
    "Please Select": "الرجاء التحديد",
    "House": "المنزل",
    "Flat": "شقه",
    "Office": "المكتب",
    "Coupon Code": "رمز الكوبون",
    "Apply": "تطبيق",
    "Payment Method": "طريقة الدفع",
    "KNET": "كي نت",
    "Visa/MasterCard": "فيزا/ماستركارد",
    "Payment Detail": "تفاصيل الدفع",
    "Discount": "الخصم",
    "Delivery Charges": "رسوم التوصيل",
    "Submit Order": "ارسال الطلب",
    "show some love to your bag": "أظهر بعض الحب لحقيبتك",
    "Fill your bag with things that make you happy": "املأ حقيبتك بالأشياء التي تسعدك",
    "Profile": "الصفحه الشخصيه",
    "Logout": "تسجيل الخروج",
    "My Account": "حسابي",
    "My Orders": "طلباتي",
    "Update Account": "تحديث الحساب",
    "Update": "تحديث",
    "Change Password": "تغيير كلمة المرور",
    "New Password": "كلمة المرور الجديدة",
    "Old Password": "كلمة المرور القديمه",
    "Confirm New Password": "تأكيد كلمة المرور الجديدة",
    "This Field is required": "هذه الخانة مطلوبه",
    "Amount": "كميه",
    "Add Items": "إضافة عناصر",
    "you may also like": "قد يعجبك ايضا",
    "No items in cart (Add items)": "لا يوجد منتجات في سلة التسوق (إضافة منتجات)",
    "ordered successfully": "شكرًا لك على طلبك",
    "ordered successfully description": "تم تقديم طلبك بنجاح",
    "ordered failed": "حدث خطأ ما!",
    "Description": "الوصف",
    "ordered failed description": "لم يتم استكمال الدفع",
    "Cancel": "إلغاء",
    "Cash in Delivery": "نقدا عند التسليم",
    "canceled": "تم الإلغاء",
    "out of stock": "نفذ من المخزن",
    "Stock": "المخزن",
    "try again": "حاول مرة أخرى",
    "ordered": "تم الطلب",
    "process": "قيد التنفيذ",
    "shipped": "تم الشحن",
    "delivered": "تم التسليم",
    "Install": "التثبيت",
    "Install Price": "سعر التثبيت",
    "Add": "إضافة",
    "Buy it again": "اشتري مره أخري",
    "Add Address First": "اضف العنوان اولا",
    "There are No Products": "لا يوجد منتجات",
    "Product Added To Cart": "تم إضافة المنتج إلى سلة التسوق",
    "Forget Password?": "هل نسيت كلمه المرور؟",
    "Default": "الافتراضية",
    "Forget Password": "نسيت كلمه المرور",
    "Back": "الي الخلف",
    "Delete": "حذف",
    "Address Added Successfully.": "تمت إضافة العنوان بنجاح.",
    "Address Updated Successfully.": "تمت تعديل العنوان بنجاح.",
    "Address Deleted Successfully.": "تمت حذف العنوان بنجاح.",
    "Message Sent Successfully.": "تم إرسال الرسالة بنجاح.",
    "First Name": "الاسم الأول",
    "Last Name": "اسم العائلة",
    "Message Required": "الرسالة المطلوبة",
    "promo code is invalid!": "الرمز الترويجي غير صالح!",
    "Successfully Applied": "تم التطبيق بنجاح",
    "Remove from Cart": "إزالة من السلة",
    "Remove": "إزاله",
    "Search": "بحث",
    "Classic kitchen": "مطبخ كلاسيكي",
    "Modern kitchen": "المطبخ الحديث",
    "No Address": "لا يوجد عنوان",
    "Add New Address": "إضافة عنوان جديد",
    "Set Default": "تعيين كافتراضي",
    "Edit": "تعديل",
}