import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProductsService from '../../../services/ProductsService';
import Loader from '../../../common/Loader';
import { Swiper, SwiperSlide } from 'swiper/react';
import ProductCard from '../../../common/ProductCard';

const NewArrivals = () => {
  const [products, setProducts] = useState([])
  const [loader, setLoader] = useState(false)
  const {t} = useTranslation()
  const productsService = new ProductsService()

  useEffect(()=>{
    let params = {
      newIn: true,
      offset: 0,
      limit: 8
    }
    setLoader(true)
    productsService?.getList(params).then(res=>{
      if(res?.status === 200){
        let info = res?.data?.data?.data
        setProducts(info)
      }
      setLoader(false)
    }).catch(()=> setLoader(false))
  },[])

  return (<div className='home-products container'//data-aos="fade-down-left"
  >
    <div className='sec-title'>
      <h4>{t("New Arrivals")}</h4>
      <Link to='/products/newIn'>{t('View All')}</Link>
    </div>
    {loader ? <div className='d-flex justify-content-center py-5'>
        <Loader />
      </div> : <div className='row mt-3'>
      <Swiper
        modules={[]}
        slidesPerView={2}
        autoplay={{ delay: 1500 }}
        spaceBetween={10}
        pagination={{ clickable: true }}
        breakpoints={{
          640: { slidesPerView: 2 },
          768: { slidesPerView: 3 },
          1024: { slidesPerView: 3.5 },
        }}
        className="mySwiper"
      >
        {products?.map((product,index)=>{
          return <SwiperSlide key={index}>
            <ProductCard product={product} />
          </SwiperSlide>
        })}
      </Swiper>
    </div>}
    </div>
  );
};

export default NewArrivals;
