import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import './style.css'
import CartService from "../../services/CartService";
import { addToCart } from "../../store/actions/AuthActions";
import Rules from "./Rules";

const RentModal = ({rentModal, setRentModal, amount, item})=>{
    const [formData, setFormData] = useState({
        days: 1,
        start: ''
    })
    const max = 30
    const [loading, setLoader] = useState(false)
    const [acceptModal, setAcceptModal] = useState(false)
    const [total, setTotal] = useState(0)
    const lang = useSelector(state=> state?.lang?.lang)
    const user = useSelector(state => state?.user)
    const cartService = new CartService()
    const {t} = useTranslation()
    const dispatch = useDispatch()

    useEffect(()=> {
        let tot = 0
        if(formData?.days === 1){
            tot = item.offer ? (item.offerPrice*amount) : (item?.price*amount)
        } else {
            tot = item.offer ? (item?.second_offerPrice*amount*formData?.days) : (item?.second_price*amount*formData?.days)
        }
        setTotal(tot)
    }, [formData?.days])

    const submit = () => {
        setAcceptModal(true)
    }

    const rentFunction = () => {
        let data = {
            products: [{
                product_id: item?.id,
                amount: amount,
                is_install: true,
                days_number: formData?.days,
                rent_day: formData?.start,
            }]
        }
        if(!!user?.user){
            cartService.create(data).then(res=>{
                if(res?.status === 201){
                    toast.success(t("Product Added To Cart"));
                    dispatch(addToCart({
                        product: item,
                        amount: amount,
                        is_install: true,
                        days_number: formData?.days,
                        rent_day: formData?.start,
                    }))
                    setRentModal(false)
                }
            }).catch(e=> {
                if(e.response?.data?.message === "product_is_already_Exist_in_your_cart"){
                    let data ={
                        product_id: item.id,
                        amount: item?.amount,
                        days_number: formData?.days,
                        rent_day: formData?.start,
                    }
                    cartService.update(data).then(res=>{
                        if(res?.status === 200){
                            toast.success(t("Product Added To Cart"));
                            setRentModal(false)
                        }
                    })
                    return
                }
                toast.error(e.response?.data?.message?.replaceAll('_', ' '))
            })
        } else {
            toast.success(t("Product Added To Cart"));
            dispatch(addToCart({
                product: item,
                amount: amount,
                is_install: true,
                days_number: formData?.days,
                rent_day: formData?.start,
            }))
            setRentModal(false)
            setTimeout(()=> setLoader(false), [500])
        }
    }
    return(
        <Modal 
            className={lang === 'en' ? "en fade rent-modal" : "ar fade rent-modal"} 
            style={{
                textAlign: lang === 'en' ? 'left' : 'right',
                direction: lang === 'en' ? 'left' : 'right',
            }} 
            show={rentModal} 
            onHide={()=>{
                setRentModal()
            }}>
            <AvForm className='form-horizontal pt-3' onValidSubmit={submit}>
                <Modal.Body>
                    <Row>
                        <Col md={12} className="mb-3">
                            <label>{t("Amount")}</label>
                            <p className="mb-0">{amount}</p>
                        </Col>
                        <Col md={12}>
                            <label>{t("Days")}</label>
                        </Col>
                        <Col className='mb-3'>
                            <Button 
                                variant='secondary' 
                                className="w-100 days-button"
                                disabled={formData?.days === 1}
                                onClick={()=> setFormData({...formData, days: (Number(formData.days)-1)})}
                            >-</Button>
                        </Col>
                        <Col className="h-100 mb-3">
                            <AvField
                                type='number'
                                placeholder={t("Days")}
                                bsSize="lg"
                                name='days'
                                min={1}
                                className='text-center h-100 mb-0'
                                max={max}
                                value={formData.days}
                                onChange={(e) => setFormData({...formData, days: e.target.value})}
                            />
                        </Col>
                        <Col className='mb-3'>
                            <Button 
                                variant='secondary' 
                                className="w-100 days-button"
                                disabled={formData?.days === max}
                                onClick={()=> setFormData({...formData, days: (Number(formData.days)+1)})}
                            >+</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <AvField
                                label={t("Start Day")}
                                type='date'
                                placeholder={t("Start Day")}
                                bsSize="lg"
                                min={`${new Date().getFullYear()}-${new Date().getMonth()+1 < 10 ? `0${new Date().getMonth()+1}` : new Date().getMonth()+1}-${new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()}`}
                                name='start'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: `${t("This Field is required")}`
                                    }
                                }}
                                value={formData.start}
                                onChange={(e) => setFormData({...formData, start: e.target.value})}
                            />
                        </Col>
                    </Row>
                    <hr />
                    <div className="total">
                        <label>{t("total")}:</label>
                        <label>{total?.toFixed(3)} {t("KWD")}</label>
                    </div>
            </Modal.Body>
            <Modal.Footer className="actions justify-content-between">
                <Button onClick={setRentModal} variant="secondary light">
                    {t("Cancel")}
                </Button>
                <Button 
                        variant="primary" 
                        type='submit'
                        disabled={loading}
                    >{t("Add")}</Button>
            </Modal.Footer>
            </AvForm>
            {acceptModal && <Rules rulesModal={acceptModal} setRulesModal={setAcceptModal} rentFunction={rentFunction} />}
        </Modal>)
}

export default RentModal;