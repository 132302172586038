import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import './style.css'
import AboutService from "../../services/AboutService";

const Rules = ({rulesModal, setRulesModal, rentFunction})=>{
    const [formData, setFormData] = useState([])
    const [loading, setLoader] = useState(false)
    const [accept, setAccept] = useState(false)
    const lang = useSelector(state=> state?.lang?.lang)
    const {t} = useTranslation()

    useEffect(()=>{
        new AboutService()?.getList({type: 'faqs'}).then(res=>{
          if(res?.status === 200){
            setFormData(res?.data?.data)
          }
        })
      }, [])

    const submit = () => {
        if(accept){
            rentFunction()
        }
    }
    return(
        <Modal 
            className={lang === 'en' ? "en fade accept-modal" : "ar fade accept-modal"} 
            style={{
                textAlign: lang === 'en' ? 'left' : 'right',
                direction: lang === 'en' ? 'left' : 'right',
            }} 
            show={rulesModal} 
            onHide={()=>{
                setRulesModal()
            }}>
            <AvForm className='form-horizontal pt-3' onValidSubmit={submit}>
                <Modal.Body>
                    <Row>
                        {formData?.map((data,index) =>{
                            return <Col md={12} className="mb-2 rule" key={index}>
                            <label>{lang === 'ar' ? data?.title_ar : data?.title_en}</label>
                            <p dangerouslySetInnerHTML={{ __html: lang === 'en' ? data?.description_en : data?.description_ar }}></p>
                        </Col>
                        })}
                    </Row>
                    <div>
                        <label>
                            <input 
                                type="checkbox"
                                name='accept'
                                className="mx-1"
                                value={accept}
                                onChange={e=> setAccept(e.target.checked)}
                            />
                            {t("Please agree to the following terms before proceeding")}
                        </label>
                    </div>
            </Modal.Body>
            <Modal.Footer className="actions justify-content-between">
                <div></div>
                <Button 
                        variant="primary" 
                        type='submit'
                        disabled={!accept}
                    >{t("Add")}</Button>
            </Modal.Footer>
            </AvForm>
        </Modal>)
}

export default Rules;