import { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import BannerService from '../../../services/BannerService';
import Loader from '../../../common/Loader';
import './style.css'
import { useTranslation } from 'react-i18next';
import header from '../../../assets/header.png'
import { useSelector } from 'react-redux';
function Header() {
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)
    const bannerService = new BannerService()
    const {t} = useTranslation()
    const lang = useSelector(state => state?.lang?.lang)

    useEffect(()=>{
      setLoader(true)
      bannerService?.getList().then(res=>{
        if(res?.status === 200){
          let info = res?.data?.data
          setData(info)
        }
        setLoader(false)
      }).catch(()=> setLoader(false))
    },[])

  return (
    <div className='header-home'>
      {loader ? <div className='d-flex justify-content-center py-5'>
        <Loader />
      </div> : <><Carousel data-bs-theme="dark" controls='false' touch={'true'}>
          {data?.map((item, index)=>{
              return <Carousel.Item key={index}>
                <img
                  className="d-block w-100"
                  src={item?.image}
                  alt="slide"
                  // style={{borderRadius: '25px'}}
                />
                <div className='details'>
                  <p className='text-primary tag'>{t("THE RENT")}</p>
                  <p className='title'>{lang === 'en' ? item?.title_en : item?.title_ar}</p>
                  <p className='description'>{lang === 'en' ? item?.description_en : item?.description_ar}</p>
                  <button>{t("Get Started")}</button>
                </div>
            </Carousel.Item>
          })}
      </Carousel>
      </>}
    </div>
  );
}

export default Header;