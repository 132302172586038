import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProductsService from '../../../services/ProductsService';
import Loader from '../../../common/Loader';
import { Swiper, SwiperSlide } from 'swiper/react';
import ProductCard from '../../../common/ProductCard';

const  BestSellingProducts = () => {
  const [products, setProducts] = useState([])
  const {t} = useTranslation()
  const productsService = new ProductsService()
  const [loader, setLoader] = useState(false)

  useEffect(()=>{
    let params = {
      bestSeller: true,
      offset: 0,
      limit: 8
    }
    setLoader(true)
    productsService?.getList(params).then(res=>{
      if(res?.status === 200){
        let info = res?.data?.data?.data
        setProducts(info)
      }
      setLoader(false)
    }).catch(()=> setLoader(false))
  },[])

  if(products?.length === 0){
    return
  }
  return (<div className='home-products container'>
    <div className='sec-title'>
      <h4>{t("Best Selling Products")}</h4>
      <Link to='/products/bestSeller'>{t('View All')}</Link>
    </div>
    <div className='row mt-3'>
      {loader ? <div className='d-flex justify-content-center py-5'>
        <Loader />
      </div> : <Swiper
        slidesPerView={2}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        modules={[]}
        breakpoints={{
          640: { slidesPerView: 2, spaceBetween: 10 },
          768: { slidesPerView: 3, spaceBetween: 20 },
          1024: { slidesPerView: 3.5, spaceBetween: 30 },
        }}
        className="mySwiper"
      >
        {products?.map((product, index) => {
          return <SwiperSlide key={index}>
            <ProductCard product={product} />
          </SwiperSlide>
        })}
      </Swiper>}
    </div>
  </div>
  );
};

export default  BestSellingProducts;
