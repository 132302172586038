export const en = {
    "THE RENT": "THE RENT",
    "Get Started": "Get Started",
    "Start From": "Start From",
    "home": "Home",
    "total": "Total",
    "products": "Products",
    "categories": "Categories",
    "about-us": "About Us",
    "Quick Links": "Quick Links",
    "Contact Details": "Contact Details",
    "Follow Us": "Follow Us",
    "Legal": "Legal",
    "Start Day": "Start Day",
    "The page you were looking for is not found!": "The page you were looking for is not found!",
    "You may have mistyped the address or the page may have moved.": "You may have mistyped the address or the page may have moved.",
    "KWD": "KWD",
    "Shop By Category": "Shop By Category",
    "View All": "View All",
    "Explore All": "Explore All",
    "Explore": "Explore",
    "New Arrivals": "New Arrivals",
    "Best Selling": "Best Selling",
    "Exclusive Products": "Exclusive Products",
    "Feature Products": "Feature Products",
    "QUALITY": "QUALITY BROUGHT TO YOU BY MASTER HN",
    "Best Selling Products": "Best Selling Products",
    "DOWNLOAD MASTER HN": "DOWNLOAD MASTER HN",
    "Style Up Your Looks": "Style Up Your Looks",
    "All Rights": "All Rights reserved",
    "Hasan Al Naser": "Hasan Al Naser",
    "New Collection": "New Collection",
    "Powered By": "Powered By",
    "Total Price": "Total Price",
    "Continue": "Continue",
    "Install": "Install",
    "Install Price": "Install Price",
    "Your Cart is Empty": "Your Cart is Empty",
    "Cart": "Cart",
    "Send Message": "Send Message",
    "Period": "Period",
    "Days": "Days",
    "Please fill out the form below to schedule a consultation or ask any questions. Our team will get back to you promptly.": "Please fill out the form below to schedule a consultation or ask any questions. Our team will get back to you promptly.",
    "We're Here to Help with All Your Desert  Needs": "We're Here to Help with All Your Desert Needs",
    "KWD / Per Day": "KWD / Per Day",
    "KWD / Per Days": "KWD / Per Days",
    "Download Pdf": "Download Pdf",
    "Style Up": "Style Up",
    "Add To Cart": "Add To Cart",
    "contact": "Contact",
    "Please agree to the following terms before proceeding": "Please agree to the following terms before proceeding",
    "Name": "Name",
    "Email": "Email",
    "Phone": "Phone",
    "Message": "Message",
    "No Orders": "No Orders",
    "CONTACT US ON WHATSAPP": "CONTACT US ON WHATSAPP",
    "GET IN TOUCH": "GET IN TOUCH",
    "Product": "Product",
    "Price": "Price",
    "Quantity": "Quantity",
    "Total": "Total",
    "Sub Total": "Sub Total",
    "Payment Details": "Payment Details",
    "Proceed": "Do You Want To Proceed To Checkout As",
    "Login": "Login",
    "Continue as a Guest": "Continue as a Guest",
    "Login Into Your Account": "Login Into Your Account",
    "Create New Account": "Create New Account",
    "Password": "Password",
    "Don't Have Account": "Don't Have Account",
    "Sign Up": "Sign Up",
    "Have Account": "Have Account",
    "Checkout": "Checkout",
    "User Details": "User Details",
    "Full Name": "Full Name",
    "Address Details": "Address Details",
    "Details": "Details",
    "Area": "Area",
    "Block": "Block",
    "Street": "Street",
    "Avenue": "Avenue",
    "Floor Number": "Floor Number",
    "Optional": "Optional",
    "Other Instructions": "Other Instructions",
    "Address Type": "Address Type",
    "Building Number": "Building Number",
    "Floor": "Floor",
    "Apartment Number": "Apartment Number",
    "House Number": "House Number",
    "House": "House",
    "Flat": "Flat",
    "Office": "Office",
    "Office Number": "Office Number",
    "Cancel Order": "Cancel Order",
    "Cancel Message": "Kindly note that this action can't be undone and the order will Cancel",
    "Close": "Close",
    "Done": "Done",
    "Extra Directions": "Extra Directions",
    "Please Enter": "Please Enter",
    "Please Select": "Please Select",
    "Coupon Code": "Coupon Code",
    "Apply": "Apply",
    "Payment Method": "Payment Method",
    "Payment": "Payment",
    "KNET": "KNET",
    "Visa/MasterCard": "Visa/MasterCard",
    "Payment Detail": "Payment Detail",
    "Discount": "Discount",
    "Delivery Charges": "Delivery Charges",
    "Submit Order": "Submit Order",
    "show some love to your bag": "Show Some Love to your Bag",
    "Fill your bag with things that make you happy": "Fill your bag with things that make you happy",
    "Profile": "Profile",
    "Logout": "Logout",
    "My Account": "My Account",
    "My Orders": "My Orders",
    "Cash in Delivery": "Cash in Delivery",
    "Update Account": "Update Account",
    "Update": "Update",
    "Change Password": "Change Password",
    "New Password": "New Password",
    "Old Password": "Old Password",
    "Confirm New Password": "Confirm New Password",
    "This Field is required": "This Field is required",
    "you may also like": "You may also like",
    "Amount": "Amount",
    "Add Items":"Add Items",
    "No items in cart (Add items)": "No items in cart (Add items)",
    "ordered successfully": "Thank you for your order",
    "ordered successfully description": "Your order has been submitted successfully",
    "ordered failed": "Something went wrong!",
    "ordered failed description": "The payment not proceed",
    "Description": "Description",
    "Cancel": "Cancel",
    "try again": "Try Again",
    "out of stock": "Out of Stock",
    "Stock": "Stock",
    "canceled": "Canceled",
    "ordered": "Ordered",
    "process": "Process",
    "shipped": "Shipped",
    "delivered": "Delivered",
    "Add": "Add",
    "Add Address First": "Add Address First",
    "Buy it again": "Buy it again",
    "There are No Products": "There are No Products",
    "Product Added To Cart": "Product Added To Cart",
    "Forget Password?": "Forget Password?",
    "Default": "Default",
    "Forget Password": "Forget Password",
    "Back": "Back",
    "Delete": "Delete",
    "Address Added Successfully.": "Address Added Successfully.",
    "Address Updated Successfully.": "Address Updated Successfully.",
    "Address Deleted Successfully.": "Address Deleted Successfully.",
    "Message Sent Successfully.": "Message Sent Successfully.",
    "First Name": "First Name",
    "Last Name": "Last Name",
    "Message Required": "Message Required",
    "promo code is invalid!": "Promo Code is Invalid!",
    "Successfully Applied": "Successfully Applied",
    "Remove from Cart": "Remove from Cart",
    "Remove": "Remove",
    "Search": "Search",
    "view": "View",
    "status": "Status",
    "Order Date": "Order Date",
    "Order ID": "Order ID",
    "Classic kitchen": "Classic Kitchen",
    "Modern kitchen": "Modern Kitchen",
    "No Address": "No Address",
    "Address": "Address",
    "Add New Address": "Add New Address",
    "Address Name": "Address Name",
    "Set Default": "Set Default",
    "Edit": "Edit",
}