import { useEffect, useState } from 'react'
import './style.css'
import trash from '../../assets/trash.svg'
import cartImg from '../../assets/cartIllustartion.svg'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, Table } from 'react-bootstrap'
import { changeIsInstall, decreaseProduct, increaseProduct, removeProduct } from '../../store/actions/AuthActions'
import { useTranslation } from 'react-i18next'
import CheckLogin from './CheckLogin'
import CartService from '../../services/CartService'
import Loader from '../../common/Loader'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'

const Cart = () =>{
    const [cartProducts, setCartProducts] = useState([])
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const user = useSelector(state => state?.user.user)
    const userData = useSelector(state => state?.user)
    const lang = useSelector(state => state?.lang?.lang)
    const cart = useSelector(state => state?.user?.cart)
    const cartService = new CartService()

    useEffect(()=>{
        setLoading(true)
        if(!!user){
            cartService.getList().then(res=>{
                if(res?.status === 200){
                    let data = res?.data.data
                    setCartProducts(data?.sub_carts)
                    setTotalPrice(data?.sub_total.toFixed(3))
                }
                setLoading(false)
            }).catch((e) => {
                setLoading(false)
                if(e?.response?.data?.message === "cart_not_Exist"){
                    localStorage.removeItem('rentCart')
                }
            })
        } else {
            setLoading(false)
            let totalP = cart?.map(res=> {
                let tot = 0
                if(res?.days_number === 1){
                    tot = res?.product?.offer ? (res?.amount*res?.product?.offerPrice) : (res?.amount*res?.product?.price)
                } else {
                    tot = res?.product?.offer ? (res?.amount*res?.product?.second_offerPrice) : (res?.amount*res?.product?.second_price)
                }
                return tot*res?.days_number
            }).reduce((accumulator, currentValue) => {
                return accumulator + currentValue;
            }, 0)
            let totalIns = cart?.filter(res=> res?.is_install)?.map(res=> {
                return res?.product?.install
            }).reduce((accumulator, currentValue) => {
                return accumulator + currentValue;
            }, 0)
            setTotalPrice((totalP+totalIns).toFixed(3))
            setCartProducts(cart)
        }
    },[shouldUpdate, userData])

    useEffect(()=>{
        setTimeout(()=> setShouldUpdate(prev=> !prev),200)
    },[userData.isLogin])

    const removeProductFromCart = (product) => {
        let data ={
            product_id: product.product_id
        }
        if(!!user){
            cartService.remove(data).then(res=>{
                if(res?.status === 200){
                    toast.success(t("Remove from Cart"))
                    dispatch(removeProduct(product))
                    setShouldUpdate(prev=> !prev)
                }
            }).catch(e=> toast?.error(e?.response?.data?.message?.replaceAll('_',' ')))
            return
        }
        dispatch(removeProduct(product))
    }

    const changeValue = (product, name, val) => {
        let data ={
            product_id: product.id,
            [name]: val,
        }
        cartService.update(data).then(res=>{
            if(res?.status === 200){
                setShouldUpdate(prev=> !prev)
            }
        })
    }

    if(loading){
        return <div className='d-flex align-items-center justify-content-around' style={{minHeight: '80vh'}}>
            <Loader />
        </div>
    }

    return <div className="cart">
        <div className='container'>
        <Card style={{border: 'none'}}>
            <CardBody>
            {cartProducts?.length > 0 ? <div className='row'>
                <div className='col-md-12 p-0'>
                    <Card className='d-block' style={{border: 'none'}}>
                        <CardBody className='p-0'>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th>{t("Price")}</th>
                                        <th>{t("Quantity")}</th>
                                        <th>{t("Install")}</th>
                                        <th>{t("Period")}</th>
                                        <th>{t("Start Date")}</th>
                                        <th>{t("Total")}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {cartProducts?.map((product, index) => {
                                    return <tr key={index} className='product-cart'>
                                        <td>
                                            <img src={!!product?.product?.product_images?.length ? product?.product?.product_images[0]?.url : ''} alt='img' width={100} height={100} />
                                        </td>
                                        <td>
                                            <h4 className='m-0'>{lang === 'en' ? product?.product?.name_en : product?.product?.name_ar}</h4>
                                        </td>
                                        <td>
                                            <h5 className='mb-0'>
                                                {product?.days_number === 1 && (product?.product?.offer ? product?.product?.offerPrice.toFixed(3) : product?.product?.price.toFixed(3))} 
                                                {product?.days_number > 1 && (product?.product?.offer ? product?.product?.second_offerPrice.toFixed(3) : product?.product?.second_price.toFixed(3))} 
                                                {t("KWD")}
                                            </h5>
                                        </td>
                                        <td>
                                            <div className='two text-center'>
                                                        <button 
                                                            className='prod-btn' 
                                                            disabled={product?.product?.amount === product?.amount}
                                                            style={{cursor: product?.product?.amount === product?.amount ? 'not-allowed' : 'pointer'}}
                                                            onClick={()=> {
                                                            if(!!user){
                                                                changeValue(product?.product, 'amount', product?.amount+1)
                                                            }
                                                            dispatch(increaseProduct(product))
                                                        } }>
                                                            +
                                                        </button>
                                                        <span style={{fontSize: '20px'}} className='mx-3'>{product?.amount}</span>
                                                        <button 
                                                            className='prod-btn minus' 
                                                            disabled={product?.amount === 1} 
                                                            style={{cursor: product?.amount > 1 ? 'pointer' : 'not-allowed'}}
                                                            onClick={()=> {
                                                                if(!!user){
                                                                    changeValue(product?.product, 'amount', product?.amount-1)
                                                                }
                                                                dispatch(decreaseProduct(product))
                                                            }}
                                                        >
                                                            -
                                                        </button>
                                            </div>
                                        </td>
                                        <td>
                                            <label className='d-flex is-install text-nowrap' style={{gap: '8px'}} for={`is_install${index}`}>
                                                <input 
                                                    type='checkbox'
                                                    id={`is_install${index}`}
                                                    checked={product?.is_install} 
                                                    onChange={e=> {
                                                        if(!!user){
                                                            changeValue(product?.product, 'is_install', e?.target?.checked)
                                                        }
                                                        dispatch(changeIsInstall(product))
                                                    }} 
                                                />
                                                {product?.product?.install} {t("KWD")}
                                            </label>
                                        </td>
                                        <td>
                                            <p className='mb-0 text-nowrap' style={{fontSize: '18px'}}>{product?.days_number} {t("Days")}</p>
                                        </td>
                                        <td>
                                            <p className='mb-0 text-nowrap' style={{fontSize: '18px'}}>{product?.rent_day?.split('T')[0]}</p>
                                        </td>
                                        <td>
                                            <h4 className='text-primary text-nowrap mb-0'>
                                                {product?.days_number === 1 && (product?.product?.offer ? 
                                                    ((product?.amount*product?.product?.offerPrice)+(product?.is_install ? product?.product?.install : 0)).toFixed(3) : 
                                                    ((product?.amount*product?.product?.price)+(product?.is_install ? product?.product?.install : 0)).toFixed(3))}
                                                
                                                {product?.days_number > 1 && (product?.product?.offer ? 
                                                    ((product?.amount*product?.product?.second_offerPrice*product?.days_number)+(product?.is_install ? product?.product?.install : 0)).toFixed(3) : 
                                                    ((product?.amount*product?.product?.second_price*product?.days_number)+(product?.is_install ? product?.product?.install : 0)).toFixed(3))} 
                                                
                                                {t("KWD")}
                                            </h4>
                                            {/* {!!user && <h4 className='text-primary mb-0'>
                                                {product?.days_number === 1 && (product?.product?.offer ? 
                                                ((product?.amount*product?.product?.offerPrice)+(product?.is_install ? product?.product?.install : 0)).toFixed(3) : 
                                                ((product?.amount*product?.product?.price)+(product?.is_install ? product?.product?.install : 0)).toFixed(3))}

                                                {product?.days_number > 1 && (product?.product?.offer ? 
                                                ((product?.amount*product?.days_number*product?.product?.offerPrice)+(product?.is_install ? product?.product?.install : 0)).toFixed(3) : 
                                                ((product?.amount*product?.days_number*product?.product?.second_price)+(product?.is_install ? product?.product?.install : 0)).toFixed(3))}
                                                {/* {product?.product?.offer ? 
                                                (product?.amount*product?.days_number*(product?.product?.price-product?.product?.offerPrice)).toFixed(3) : 
                                                (product?.amount*product?.days_number*product?.product?.price).toFixed(3)}  */}
                                        </td>
                                        <td>
                                            <button className='trash' onClick={()=> removeProductFromCart(product)}>
                                                <img src={trash} alt='trash' width={22} height={22} />
                                            </button>
                                        </td>
                                            {/* <div className='row'>
                                                <div className='col-md-9 col-12 d-flex' style={{gap: '16px'}}>
                                                    <img src={product?.product?.product_images[0]?.url} alt='img' width={90} height={90} />
                                                    <div>
                                                        <h4 className='m-0'>{lang === 'en' ? product?.product?.name_en : product?.product?.name_ar}</h4>
                                                        <Badge className='mb-2' variant="primary">{lang === 'en' ? product?.product?.category?.name_en : product?.product?.category?.name_ar}</Badge>
                                                        <h5 className='mb-2'>{product?.amount} * {product?.product?.price.toFixed(3)}</h5>
                                                    </div>
                                                </div>
                                                <div className='col-md-3 col-12 mob-view-actions'>
                                                    <div className='one text-center'>
                                                    {<h4 className='text-primary mb-0'>{(Number(product?.amount)*Number(product?.product?.price)).toFixed(3)} {t("KWD")}</h4>}
                                                    </div>
                                                    <div className='two text-center'>
                                                        <button className='prod-btn' onClick={()=> {
                                                            if(!!user){
                                                                changeAmount(product?.product, product?.amount+1)
                                                            }
                                                            dispatch(increaseProduct(product?.product))
                                                        } }>
                                                            +
                                                        </button>
                                                        <span style={{fontSize: '20px'}} className='mx-3'>{product?.amount}</span>
                                                        <button 
                                                            className='prod-btn minus' 
                                                            disabled={product?.amount === 1} 
                                                            style={{cursor: product?.amount > 1 ? 'pointer' : 'not-allowed'}}
                                                            onClick={()=> {
                                                                if(!!user){
                                                                    changeAmount(product?.product, product?.amount-1)
                                                                }
                                                                dispatch(decreaseProduct(product?.product))
                                                            }}
                                                        >
                                                            -
                                                        </button>
                                                    </div>
                                                    <div className='three text-center mt-3'>
                                                        <button className='trash' onClick={()=> removeProductFromCart(product?.product)}>
                                                            <img src={trash} alt='trash' /> {t("Delete")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div> */}
                                    </tr>
                                })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </div>
                <div className='col-md-8 mt-4'></div>
                <div className='col-md-4 col-12 mt-4'>
                    <Card className='payment-details' style={{border: 'none',boxShadow: '0 0 12px #dedede78'}}>
                        <CardBody>
                            <h5 className='mb-4'>{t("Payment Details")}</h5>
                            <div className='d-flex justify-content-between'>
                                <h5 style={{fontSize: '18px'}}>{t("Total Price")}:</h5>
                                <h5 style={{fontSize: '18px', fontWeight: "600"}}>{totalPrice} {t("KWD")}</h5>
                            </div>
                            <div>
                                <button 
                                    className='continue w-100'
                                    onClick={()=> {
                                        if(!user){
                                            setModal(true)
                                        }else{
                                            navigate('/checkout')
                                        }
                                    }}
                                >{t("Continue")}</button>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div> : <div className='text-center' style={{marginTop: '10rem', marginBottom: '7rem'}}>
                <div className='m-auto' style={{background: '#704c2c', width: '120px', height:'120px', padding: '8px', borderRadius: '50%'}}>
                    <img src={cartImg} alt='cart' width={90} />
                </div>
                <h3 className='mt-4'>{t("No items in cart (Add items)")}</h3>
                <h4 className='mt-2'>{t("Fill your bag with things that make you happy")}</h4>
                <Link to='/' 
                    className='btn btn-primary mt-3'
                    style={{
                        borderRadius: '3px',
                        padding: '10px 30px'
                    }}
                >{t("Add Items")}</Link>
            </div>}
            </CardBody>
        </Card>
        </div>
        {modal && <CheckLogin modal={modal} setModal={()=> setModal(false)} />}
    </div>
}
export default Cart