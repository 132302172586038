import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CategoriesService from '../../../services/CategoriesService';
import { useSelector } from 'react-redux';
import Loader from '../../../common/Loader';
import './style.css'
import category1 from '../../../assets/category1.png'
import category2 from '../../../assets/category2.png'

const Category = () => {
  const [data, setData] = useState([])
  const [hasData, setHasData] = useState(null)
  const navigate = useNavigate()
  const {t} = useTranslation()
  const lang = useSelector(state => state?.lang?.lang)
  const categoriesService = new CategoriesService()
  const [loader, setLoader] = useState(false)

  useEffect(()=>{
    // setLoader(true)
    let params = {
      limit: 2,
      offset: 0
    }
    categoriesService?.getList(params).then(res => {
      if(res?.status === 200){
        let info = res?.data?.data?.data
        setData(info)
        if(res?.data?.data?.data?.length > 0){
          setHasData(1)
        } else {
          setHasData(0)
        }
      }
      // setLoader(false)
    }).catch(()=> setLoader(false))
  },[])

  return (<div className='shop-by-category container'// data-aos="fade-down"
      //data-aos-easing="linear"
      //data-aos-duration="500"
    >
    <div className='sec-title'>
      <h4>{t("categories")}</h4>
      <Link to='/categories'>{t('View All')}</Link>
    </div>
    <div className='row' 
    >
      {loader && <div className='d-flex justify-content-center py-5'>
        <Loader />
      </div>}
      {hasData === 1 && data?.map((category) => {
        return <div className='col-md-6 mb-4 col-6' key={category?.id}>
          <div className='cate h-100 position-relative' onClick={()=> navigate(`/category/${category?.id}`)}>
            <img src={category?.image} alt={category?.name} className='img w-100 h-100' />
            <div className='desc'>
              <p>{lang === 'en' ? category?.name_en : category?.name_ar}</p>
            </div>
          </div>
        </div>
      })}
    </div>
    </div>
  );
};

export default Category;
