import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";   
import knet from '../../assets/knet.svg'
import mastercard from '../../assets/mastercard.svg'
import visa from '../../assets/visa.svg'
import MHSocialMediaService from "../../services/MHSocialMediaService";
import whatsapp from '../../assets/whatsapp.svg'
import './style.css'

const Footer = () => {
    const [data, setData] = useState({
        whatsapp: ''
    })
    const {t} = useTranslation()
    const mHSocialMediaService = new MHSocialMediaService()

    useEffect(()=>{
        mHSocialMediaService.getList().then(res=>{
            if(res?.status === 200){
                setData(res?.data?.data)
            }
        })
    },[])

    const openWhatsapp = () => {
        const url = `https://wa.me/${data?.whatsapp}`;
        window.open(url, '_blank');
    }

    return <div className="mt-5 mb-0 pt-4 footer">
        {!!data?.whatsapp && <button className='whatsapp' onClick={openWhatsapp}>
            <img src={whatsapp} alt='visa' width={32} />
        </button>}
        <div className="visa text-center mb-3">
            <img src={visa} alt='visa' />
            <img src={mastercard} alt='mastercard' className="mx-2" />
            <img src={knet} alt='knet' />
        </div>
        <p className="text-center mb-2 power">&copy; For Rent {t("All Rights")}
        <span className="mx-1">{new Date().getFullYear()}</span></p>
        <p className="text-center pb-3 mb-0 power ">{t("Powered By")}
        <a rel="noreferrer" 
            href='https://www.cloudliftsolution.com/' 
            target='_blank'
            style={{
                color: '#fff',
                textDecoration: 'none',
                margin: '0 2px'
            }}
        >Cloud Lift Solutions</a>
        </p>
    </div>
}
export default Footer;