import BestSellingProducts from "./ BestSellingProducts"
import ExculsiveProducts from "./ExculsiveProducts"
import Header from "./Header"
import Category from "./Category"
import FooterTop from "../Footer/FooterTop"
import Contact from "../Contact"
import NewArrivals from "./NewArrivals"
import './style.css'

const Home = () => {
    return <>
        <Header />
        <Category />
        <BestSellingProducts />
        <NewArrivals />
        <ExculsiveProducts />
        <Contact />
        <FooterTop />
    </>
}
export default Home