import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import productBag from '../../assets/product-bag.png'
import { useTranslation } from "react-i18next";
import RentModal from "../../Pages/RentModal";
import './style.css'

const ProductCard = ({product}) => {
    const [rentModal, setRentModal] = useState(false)
    const lang = useSelector(state => state?.lang?.lang)
    const {t} = useTranslation()

    return <div className='product-home'>
      <div className="mb-2">
        <div className='image'>
          <img src={product?.product_images[0]?.url} alt='product' className='product-img' />
        </div>
        <Link to={`/product/${product?.id}`}>
          <h3 className='title px-3'>{lang ==='en' ? product?.name_en : product?.name_ar}</h3>
        </Link>
        <h4 className='stock px-3'>{t("Stock")} : {product?.amount}</h4>
      </div>

      <div>
        {(product?.offer && product?.price !== product?.offerPrice) && <p 
          className="mb-0 px-3"
          style={{
            height: '18px',
            fontSize: '14px',
            textDecoration: 'line-through'
          }}
        >
          {product?.price} {t("KWD / Per Day")}
        </p>}
        {(product?.offer && product?.second_price !== product?.second_offerPrice) && <p 
          className="mb-0 px-3"
          style={{
            height: '18px',
            fontSize: '14px',
            textDecoration: 'line-through'
          }}
        >
          {product?.second_price} {t("KWD / Per Days")}
        </p>}
        
        <div className='d-flex px-3 text-nowrap align-items-center justify-content-between'>
          {product?.offer && <p className='price'>{product?.offerPrice} {t("KWD / Per Day")}</p>}
          {!product?.offer&& <p className='price'>{product?.price} {t("KWD / Per Day")}</p>}
          <button className='productBag-lab' onClick={()=> setRentModal(true)}>
            <img src={productBag} alt='productBag' width={20} />
          </button>
        </div>

        {product?.offer && <p 
          className="mb-0 px-3"
          style={{
            height: '18px',
            fontSize: '16px',
          }}
        >
          {product?.second_offerPrice} {t("KWD / Per Days")}
        </p>}
        {!product?.offer && <p 
          className="mb-0 px-3"
          style={{
            height: '18px',
            fontSize: '16px',
          }}
        >
          {product?.second_price} {t("KWD / Per Days")}
        </p>}
        <div className='productBag-mob px-3 mt-3'>
          <button onClick={()=> setRentModal(true)}>
              <img src={productBag} alt='productBag' width={20} />
            </button>
          </div>
      </div>

        {rentModal && <RentModal
          rentModal={rentModal}
          setRentModal={()=> setRentModal(false)}
          amount={1}
          item={{...product}}
        />}
  </div>
}
export default ProductCard;