import React, { useEffect, useState } from 'react';
import './style.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Path from '../../common/Path';
import ProductsService from '../../services/ProductsService';
import { useSelector } from 'react-redux';
import Loader from '../../common/Loader';
import ProductCard from '../../common/ProductCard';

const Products = () => {
  const [search, setSearch] = useState('')
  const [data, setData] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const {t} = useTranslation()
  const lang = useSelector(state => state?.lang?.lang)
  const productsService = new ProductsService()
  const [loader, setLoader] = useState(false)

  useEffect(()=>{
    let param = {}
    if(!!search) param['search'] = search
    if(location.pathname.includes('category')) param['category_id'] = location.pathname.split('/')[2]
    if(location.pathname.includes('bestSeller')) param['bestSeller'] = true
    if(location.pathname.includes('offer')) param['offer'] = true
    if(location.pathname.includes('newIn') || location.pathname.includes('new-collection')) param['newIn'] = true
    
    setLoader(true)
    productsService?.getList(param).then(res=>{
      if(res?.status === 200){
        let info = res?.data?.data?.data
        setData(info)
      }
      setLoader(false)
    }).catch(()=> setLoader(false))
  },[location, search])

  return (<div className='products container'>
    <Path 
      title={location.pathname.includes('new-collection') ? 'New Collection' : 'products' }
      setSearch={setSearch}
    />

    <div className='row'>
      {loader ? <div className='d-flex justify-content-center py-5'>
        <Loader />
      </div> : data?.length > 0 ? data?.map((product, index) => {
        return <div className='col-md-3 mb-4 col-6' key={index}>
          <ProductCard product={product} />
        </div>
      }) : <h3 className='text-center'>{t("There are No Products")}</h3>}
    </div>
    </div>
  );
};

export default Products;
