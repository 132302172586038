import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/logo.png'
import phone from '../../assets/phone.png'
import email from '../../assets/email.png'
import location from '../../assets/location.png'
import { NavLink } from 'react-router-dom';
import MHSocialMediaService from '../../services/MHSocialMediaService';

const FooterTop = () => {
    const [data, setData] = useState({
        facebook: '',
        instagram: '',
        twitter: '',
        youtube: '',
        snapchat: '',
        qr_code: '',
    })
    const {t} = useTranslation()
    const mHSocialMediaService = new MHSocialMediaService()

    useEffect(()=>{
        mHSocialMediaService.getList().then(res=>{
            if(res?.status === 200){
                setData(res?.data?.data)
            }
        })
    },[])

    return <div className="footer-top mt-5">
        <div className='container'>
            <div className='footer-logo'>
                <img src={logo} alt='logo' className='logo' />
            </div>
            <div className='row mt-5'>
                <div className='col-md-3 col-6 mb-3'>
                    <h4 className='mb-4'>{t("Quick Links")}</h4>
                    <div>
                        <NavLink to='/' className={({ isActive }) => isActive ? "active nav-link py-0 mb-2" : "nav-link py-0 mb-2"} aria-current="page" >{t('home')}</NavLink>
                        <NavLink to='/categories' className={({ isActive }) => isActive ? "active nav-link py-0 mb-2" : "nav-link py-0 mb-2"} aria-current="page">{t('categories')}</NavLink>
                        <NavLink to='/new-collection' state={''} className={({ isActive }) => isActive ? "active nav-link py-0 mb-2" : "nav-link py-0 mb-2"} aria-current="page">{t('New Collection')}</NavLink>
                        <NavLink to='/about-us' className={({ isActive }) => isActive ? "active nav-link py-0 mb-2" : "nav-link py-0 mb-2"} aria-current="page">{t('about-us')}</NavLink>
                    </div>
                </div>
                <div className='col-md-3 col-6 mb-3'>
                    <h4 className='mb-4'>{t("Contact Details")}</h4>
                    <div>
                        {!!data?.call_us && <p className='mb-2'><img src={phone} alt='phone' width={16} className='mx-2' />{data?.call_us}</p>}
                        {!!data?.gmail && <p className='mb-2'><img src={email} alt='email' width={16} className='mx-2' />{data?.gmail}</p>}
                        {!!data?.address && <p className='mb-2'><img src={location} alt='location' width={16} className='mx-2' />{data?.address}</p>}
                    </div>
                </div>
                <div className='col-md-3 col-6 mb-3'>
                    <h4 className='mb-4'>{t("Follow Us")}</h4>
                    <div>
                        {/* {!!data?.facebook && <p className='mb-2'>
                            <a href={data?.facebook} target='_blank' rel="noreferrer">Facebook</a>
                        </p>} */}
                        {!!data?.instagram && <p className='mb-2'>
                            <a href={data?.instagram} target='_blank' rel="noreferrer">Instagram</a>
                        </p>}
                        {!!data?.twitter && <p className='mb-2'>
                            <a href={data?.twitter} target='_blank' rel="noreferrer">Twitter</a>
                        </p>}
                    </div>
                </div>
                <div className='col-md-3 col-6 mb-3'>
                    <h4 className='mb-4'>{t("Legal")}</h4>
                    <div>
                        {/* <p className='mb-2'>Privacy Policy</p> */}
                        <NavLink className='nav-link py-0 mb-2' to='/about-us'>{t("about-us")}</NavLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default FooterTop;