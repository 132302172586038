import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import shoppingBag from '../../assets/shopping-bag.png'
import userIcon from '../../assets/user.png'
import logo from '../../assets/logo.png'
import translate from '../../assets/translate.png'
import './style.css'
import { useEffect, useState } from 'react'
import Authentication from '../Home/Authentication'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown from 'react-bootstrap/Dropdown';
import { Logout } from '../../services/AuthService'
import { useTranslation } from 'react-i18next'
import { changeLang } from '../../store/actions/LangActions'
import { ShowLogin } from '../../store/actions/AuthActions'
import CartService from '../../services/CartService'

const Navbar = () =>{
  const [cart, setCartLength] = useState(0)
  const [shouldUpdate, setShouldUpdate] = useState(false)
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(state => state.user)
  const lang = useSelector(state => state.lang.lang)
  const { t, i18n } = useTranslation();

  const changeLanguage = () => {
    if(lang === 'en'){
      i18n.changeLanguage('ar');
      dispatch(changeLang('ar'))
    } else {
      i18n.changeLanguage('en');
    dispatch(changeLang('en'))
    }
  };

  useEffect(()=>{
    if(!!user?.user){
      new CartService().getList().then(res=>{
          if(res?.status === 200){
            setCartLength(res.data?.data?.sub_carts?.length)
          }
      }).catch(() => setCartLength(0))
    } else {
      setCartLength(user?.cart?.length || 0)
    }
  },[shouldUpdate, user?.user, user.cart])

  useEffect(()=>{
    setTimeout(()=> setShouldUpdate(prev=> !prev),200)
  },[user.isLogin])

  return <div>
    <nav className="navbar container navbar-expand-lg">
      <div className='row w-100 m-auto' style={{alignItems: 'center'}}>
        <div className='col-md-3 col-5 nav-logo'>
          <Link to='/' className="navbar-brand logo m-0 p-0" >
          <img src={logo} alt='logo'/>
        </Link>
        </div>
        <div className='col-md-5 col-3'>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse m-auto" style={{width: 'fit-content'}} id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <NavLink to='/' className={({ isActive }) => isActive ? "active nav-link py-0" : "nav-link py-0"} aria-current="page" >{t('home')}</NavLink>
              <NavLink to='/categories' className={({ isActive }) => isActive ? "active nav-link py-0" : "nav-link py-0"} aria-current="page">{t('categories')}</NavLink>
              <NavLink to='/products/new-collection' className={({ isActive }) => isActive ? "active nav-link py-0" : "nav-link py-0"} aria-current="page">{t('New Collection')}</NavLink>
              <NavLink to='/about-us' className={({ isActive }) => isActive ? "active nav-link py-0" : "nav-link py-0"} aria-current="page">{t('about-us')}</NavLink>
            </div>
          </div>
        </div>

        <div className='col-5 nav-mobile-logo' style={{textAlign: 'center'}}>
          <Link to='/' className="navbar-brand logo m-0 p-0" >
            <img src={logo} alt='logo' width={60} />
          </Link>
        </div>

        <div className='col-md-4 col-4 d-flex align-items-center' style={{justifyContent: 'end'}}>
          {!!user?.user ?  <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" className='p-0' style={{background: 'none', border: 'none', paddingRight: '0'}}>
            <img src={user?.user?.avatar || userIcon} alt='user' height={28} width={28} style={{borderRadius: '50%'}}/>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item style={{color: '#000'}} onClick={()=> navigate('/profile')}>
              {t("Profile")}
            </Dropdown.Item>
            <Dropdown.Item style={{color: '#000'}} onClick={()=> Logout(user?.accessToken, dispatch, navigate, location?.pathname)}>
              {t("Logout")}
            </Dropdown.Item>
          </Dropdown.Menu>
          </Dropdown> : <button type='button' onClick={()=> dispatch(ShowLogin(true))} className="login-icon nav-link p-0">
            <img src={userIcon} alt='user' width={28}/>
            <span className='text-primary mx-1' style={{fontSize: '18px'}}>{t("Login")}</span>
          </button>}

          <Link to='/cart' className="nav-link cart-icon p-0 d-flex position-relative" style={{margin: '0 16px', alignItems: 'center'}}>
            <img src={shoppingBag} alt='shoppingBag' width={26} height={26}/>
            <span className='text-primary mx-1'>{t("Cart")}</span>
            {cart > 0 && <p className='cart-num'>{cart}</p>}
          </Link>

          <button type='button' onClick={()=> changeLanguage()} className="nav-link lang-icon p-0">
            <img src={translate} alt='user' width={27}/>
            <span className='text-primary mx-1' style={{fontSize: '18px'}}>
              {lang !== 'en' ? 'English' : 'العربية'}
            </span>
          </button>

          {/* <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className='p-0' style={{background: 'none', border: 'none'}}>
            <img src={translate} alt='translate' width={27} className='mx-1' />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item style={{color: '#000'}} onClick={()=> changeLanguage('en')}>
                English
              </Dropdown.Item>
              <Dropdown.Item style={{color: '#000'}} onClick={()=> changeLanguage('ar')}>
                اللغه العربيه
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}

          {user?.showLogin && 
            <Authentication 
              modal={user?.showLogin} 
              setModal={()=> dispatch(ShowLogin(false))}
            />
          }
        </div>
      </div>
    </nav>
  </div>
}
export default Navbar